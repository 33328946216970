<template>
  <a-modal width="100%" v-model:visible="addNewExhibitionModal">
    <SearchUser v-if="addNewExhibitionModal" role="exhibit" @addUser="addNewExhibit" :exist-users="allSlots">
    </SearchUser>
  </a-modal>

  <a-modal wrap-class-name="full-modal" v-if="currentEvent?.type === 'exhibition'" width="100%"
           :visible="currentUser !== null" @ok="saveExhibits" @cancel="currentUser = null">
    <EventExhibitions :key="currentUser?.id" ref="eventExhibitions" @fetchExhibitions="fetchExhibitions"
                      :current-user="currentUser" :all-slots="allSlots" :event-id="id"></EventExhibitions>
  </a-modal>
  <div class="editor-container" v-if="currentEvent">
    <div class="preview-block">
      <div class="fields-container">
        <div class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Название:</a-typography-title>
          <a-input placeholder="Название" @change="commit('setEventData', { val: $event.target.value, field: 'title' })"
                   :value="currentEvent.title"></a-input>
        </div>
        <div class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Описание:</a-typography-title>
          <a-textarea placeholder="Описание" :value="currentEvent.description"
                      @change="commit('setEventData', { val: $event.target.value, field: 'description' })"></a-textarea>
        </div>
        <!-- <div class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Добавить лимит участников?</a-typography-title>
          <a-radio-group :options="yesOrNo" v-model:value="isParticipantsLimit" />
        </div> -->

        <div class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Тип:</a-typography-title>
          <a-select  @change="val => commit('setEventData', { val, field: 'type' })"
                    :value="currentEvent.type">
            <a-select-option value="lecture">Лекция</a-select-option>
            <a-select-option value="excursion">Экскурсия</a-select-option>
            <a-select-option value="broadcast">Трансляция</a-select-option>
            <a-select-option value="exhibition">Выставка</a-select-option>
            <a-select-option value="record">Запись</a-select-option>
            <a-select-option value="dedicatedTour">Живая экскурсия</a-select-option>
            <a-select-option value="recordedTour">Записанная экскурсия</a-select-option>
            <a-select-option value="vdnh_excursion">Экскурсия по ВДНХ</a-select-option>
          </a-select>
        </div>
        <div v-if="currentEvent.type !== 'record'" class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Место:</a-typography-title>
          <a-input :value="currentEvent.eventPlace"
                   @change="commit('setEventData', { val: $event.target.value, field: 'eventPlace' })"></a-input>
        </div>
        <div v-if="['excursion', 'recordedTour'].includes(currentEvent.type)" class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Карта:</a-typography-title>
          <a-input :value="currentEvent.mapPath"
                   @change="commit('setEventData', { val: $event.target.value.trim(), field: 'mapPath' })"></a-input>
        </div>
        <div v-if="currentEvent.type !== 'record'" class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Дата и время начала:</a-typography-title>
          <div class="" style="display: flex;gap: 10px">
            <a-date-picker :value="currentEvent.eventDateStart"
                           @change="val => commit('setEventData', { val, field: 'eventDateStart' })"></a-date-picker>
            <a-time-picker :minute-step="5" format="HH:mm" :value="currentEvent.eventDateStart"
                           @change="val => commit('setEventData', { val, field: 'eventDateStart' })"></a-time-picker>
          </div>
        </div>
        <div v-if="currentEvent.type !== 'record'" class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Дата и время конца:</a-typography-title>
          <div class="" style="display: flex;gap: 10px">
            <a-date-picker :value="currentEvent.eventDateEnd"
                           @change="val => commit('setEventData', { val, field: 'eventDateEnd' })"></a-date-picker>
            <a-time-picker :minute-step="5" format="HH:mm" :value="currentEvent.eventDateEnd"
                           @change="val => commit('setEventData', { val, field: 'eventDateEnd' })"></a-time-picker>
          </div>

        </div>

        <!--SLOTS -->

        <div class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">
            Добавить слоты?
          </a-typography-title>
          <a-radio-group :options="yesOrNo" v-model:value="isSlots"/>
        </div>
        <div v-if="isSlots && currentEvent.type !== 'record'" class="field-el">
          <a-typography-title style="margin: 0;padding: 0" :level="5">
            Выберите длительность одного события:
          </a-typography-title>
          <a-select v-model:value="durationModel">
            <a-select-option v-for="minutes in sequence" :key="minutes" :value="index">
              {{ minutes }} минут
            </a-select-option>
          </a-select>
          <a-typography-title style="margin: 0;padding: 0" :level="5">
            Выберите рабочие часы:
          </a-typography-title>
          <a-slider :marks="marks" tooltip-visible :min="0" :max="24" range v-model:value="workTimeModel"/>

          <a-typography-title style="margin: 0;padding: 0" :level="5">
            Выберите лимит участников:
          </a-typography-title>
          <a-select v-model:value="slotLimit" :default-value="slotLimit">
            <a-select-option v-for="limit in maxSlotLimit" :key="limit" :value="index">
              {{ limit }}
            </a-select-option>
          </a-select>
        </div>

        <!--SLOTS -->
        <div class="field-el"
             v-if="!['broadcast', 'exhibition', 'recordedTour','vdnh_excursion'].includes(currentEvent.type)">
          <a-typography-title style="margin: 0;padding: 0" :level="5">Ведущий</a-typography-title>
          <a-select :value="currentEvent.leaderId" @change="val => commit('setEventData', { val, field: 'leaderId' })">
            <a-select-option v-for="item in usersList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </div>

        <div v-if="currentEvent.type === 'record'" class="field-el">
          <a-upload :action="uploadPath" @change="videoHandleChange">
            <a-button type="primary">
              <upload-outlined></upload-outlined>
              Загрузить видео
            </a-button>
          </a-upload>
        </div>

      </div>

      <div class="field-el avatar">
        <a-image :width="250" :height="250"
                 :src="currentEvent.previewUrl ? currentEvent.previewUrl : require('../../assets/img.png')"></a-image>
        <a-button type="primary" @click="$refs.preview.click()">
          Загрузить
          <input accept="image/*" type="file" hidden ref="preview" @change="uploadFile">
        </a-button>
      </div>
    </div>

    <div class="field-el" v-if="currentEvent?.type === 'exhibition'">
      <a-divider></a-divider>
      <a-button :disabled="id === 'new'" style="width: 250px" @click="addNewExhibitionModal = true">
        {{ id === 'new' ? 'Доступно при редактировании' : 'Добавить экспонат' }}
      </a-button>
      <a-table :data-source="allSlots" :columns="userColumns">
        <template #bodyCell="{ column, record }">
          <div class="" v-if="column?.key === 'cells'">
            <a-button type="primary" ghost @click="setCurrentUser(record)">Посмотреть слоты</a-button>
          </div>
          <div class="" v-if="column?.key === 'actions'">
            <a-button danger ghost @click="deleteSlot(record)">Удалить</a-button>
          </div>
        </template>


        <template #emptyText>{{
            id === 'new' ? 'Доступно при редактировании' : 'Нет добавленных экспонатов'
          }}
        </template>
      </a-table>
    </div>
    <div class="" style="display: flex;justify-content: center">
      <a-button @click="saveEvent" type="primary">Сохранить</a-button>
    </div>
  </div>
</template>

<script>
import {onBeforeRouteLeave, useRouter} from "vue-router";
import {computed, onBeforeMount, ref, watch} from "vue";
import {useStore} from "vuex";
import dayjs from "dayjs";
import EventExhibitions from "@/components/events/EventExhibitions.vue";
import SearchUser from "@/components/SearchUser.vue";
import {fetchExhibitionPavilions} from "@/store/events/serverInterationMethods";
import {appConfig} from "@/lib/appConfig";
import {UploadOutlined} from "@ant-design/icons-vue";

export default {
  name: "EventEditorPage",
  methods: {fetchExhibitionPavilions},
  components: {SearchUser, EventExhibitions, UploadOutlined},
  props: ['id'],
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const file = ref(null);
    const usersList = computed(() => store.getters.usersList);
    const currentEvent = computed(() => store.getters.currentEvent);
    const uploadPath = computed(() => `${appConfig.domain}/file`);

    const currentUser = ref(null);


    const eventExhibitions = ref(null);

    const addNewExhibitionModal = ref(false);

    const allSlots = ref([]);

    const userColumns = [
      {
        title: 'Название компании',
        dataIndex: 'clientName',
        key: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'clientEmail',
        key: 'email'
      },
      {
        title: 'Слоты',
        key: 'cells'
      },
      {
        title: 'Действия',
        key: 'actions'
      },

    ]

    const videoHandleChange = async ({file}) => {
      try {
        if (file?.response?.url) {
          store.commit('setEventData', {val: file.response.url, field: 'translationUrl'});
        }
      } catch (e) {
        console.log(e);
      }
    }

    const uploadFile = (event) => {
      try {
        file.value = event.target.files[event.target.files.length - 1];
        store.commit('setEventData', {val: URL.createObjectURL(file.value), field: 'previewUrl'});
      } catch (e) {
        console.log(e);
      }
    }

    const saveEvent = async () => {
      if (file.value) {
        await store.dispatch('uploadFile', file.value).then((url) => {
          store.commit('setEventData', {val: url, field: 'previewUrl'});
        })
      }
      await store.dispatch('saveEvent', props.id);
    };

    const fetchUsersList = (role) => {
      store.dispatch('fetchUsersList', {
        page: 1,
        limit: 1000,
        role
      })
    }


    const fetchExhibitions = () => {
      store.dispatch('fetchExhibitionPavilions', props.id).then((res) => {
        allSlots.value = res;
      })
    }

    const setCurrentUser = (val) => {
      currentUser.value = {
        id: val.clientId,
        exhibitionId: val.id
      };
    }

    const saveExhibits = () => {
      currentUser.value = null;
      eventExhibitions.value?.saveCells();
      fetchExhibitions();
    }

    const addNewExhibit = (id) => {
      store.dispatch('saveExhibits', [{
        eventId: props.id,
        clientId: id,
        order: ''
      }]).then((status) => {
        if (status === 200) {
          addNewExhibitionModal.value = false;
          fetchExhibitions()
        }
      })
    }

    const deleteSlot = (record) => {
      store.dispatch('deleteSlot', record?.id).then((status) => {
        if (status === 200) {
          const index = allSlots.value?.findIndex(item => item?.id === record?.id);
          if (index > -1) {
            allSlots.value?.splice(index, 1);
          }
        }
      });
    }


    watch(() => currentEvent.value?.type, (val) => {
      if (val === 'lecture') {
        fetchUsersList('lecture');
      }
      if (['excursion', 'dedicatedTour'].includes(val)) {
        fetchUsersList('guide');
      }
      if (val === 'exhibition' && props.id !== 'new') {
        fetchExhibitions();
      }
    });

    const isSlots = ref(false);

    const durationModel = computed({
      get: () => currentEvent.value.eventSlots?.duration,
      set: (val) => store.commit('setEventDataEventSlots', {val, field: 'duration'})
    })

    const workTimeModel = computed({
      get() {
        if (!currentEvent.value.eventSlots) {
          return [8, 17];
        }
        const {startOfWorkingDay, endOfWorkingDay} = currentEvent.value.eventSlots;
        return [Number(startOfWorkingDay), Number(endOfWorkingDay)];
      },
      set([start, end]) {
        store.commit('setEventDataEventSlots', {val: start, field: 'startOfWorkingDay'});
        store.commit('setEventDataEventSlots', {val: end, field: 'endOfWorkingDay'});
      }
    })

    const yesOrNo = [
      {label: 'Да', value: true},
      {label: 'Нет', value: false},
    ];

    const start = 5;
    const end = 1000;
    const interval = 5;

    const sequence = Array.from({length: Math.floor((end - start) / interval) + 1}, (_, index) => start + index * interval);

    const maxSlotLimit = 60;
    const slotLimit = computed({
      get: () => currentEvent.value.slotLimit,
      set: (val) => store.commit('setEventData', {val, field: 'slotLimit'})
    });

    const marks = {
      0: '00:00',
      24: '24:00'
    }

    watch(isSlots, (value) => {
      if (!value) {
        store.commit('setEventData', {val: null, field: 'eventSlots'});
        store.commit('setEventData', {val: null, field: 'slotLimit'})
      }

      if (value && !durationModel.value) {
        store.commit('setEventDataEventSlots', {val: 30, field: 'duration'})
      }
    });

    onBeforeMount(() => store.dispatch('fetchCurrentEvent', props.id).finally(() => {
      isSlots.value = !!currentEvent.value.eventSlots?.duration;
    }));

    onBeforeRouteLeave(() => {
      sessionStorage.removeItem('eventType');
      store.commit('clearTableList');
      store.commit('clearCurrentEvent');
    })

    return {
      router,
      currentEvent,
      commit: store.commit,
      usersList,

      userColumns,
      currentUser,
      allSlots,

      eventExhibitions,
      addNewExhibitionModal,

      uploadFile,
      uploadPath,
      videoHandleChange,
      dayjs,
      saveEvent,
      setCurrentUser,
      saveExhibits,
      addNewExhibit,
      fetchExhibitions,
      deleteSlot,

      yesOrNo,
      isSlots,
      durationModel,
      sequence,
      maxSlotLimit,
      slotLimit,
      workTimeModel,
      marks,
    }
  }
}
</script>

<style scoped>
.editor-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.preview-block {
  display: flex;
  gap: 10px;
}

.fields-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.field-el {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.avatar {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.full-modal:deep(.ant-modal) {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}


.ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}

.ant-modal-body {
  flex: 1;
}
</style>